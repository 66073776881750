
import { defineComponent, ref, watchEffect } from 'vue';
import { message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  props: {
    batchVisible: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    signType: {
      type: [Number, Object],
      default: () => undefined,
    },
    orderType: {
      type: Number,
      default: 1,
    },
  },
  emits: ['closeModal', 'onRefreshTable'],
  setup(props, { emit }) {
    const visible = ref(false);
    watchEffect(() => {
      visible.value = props.batchVisible;
    });
    const exceptionMark = ref(0);
    const reason = ref('');
    // 取消 关闭弹窗
    const closeModal = (flag = false) => {
      visible.value = false;
      exceptionMark.value = 0;
      reason.value = '';
      emit('closeModal', flag);
    };
    // 确认提交
    const handleOk = async () => {
      try {
        const res = await request.post(createApiUrl('/newlinkSass/order/markOrderException'), {
          reason: reason.value,
          exceptionMark: exceptionMark.value, // 订单异常标志 0--正常 1--异常
          orders: props.selectedRows,
          type: props.orderType,
        });
        if (res && res.code === 200) message.success('操作成功');
        closeModal(true);
        // emit('onRefreshTable', true);
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    return {
      reason,
      exceptionMark,
      closeModal,
      handleOk,
      visible,
    };
  },
});
