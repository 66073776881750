import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb147f42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "remark__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Remarks = _resolveComponent("Remarks")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_ctx.visible)
    ? (_openBlock(), _createBlock(_component_a_modal, {
        key: 0,
        visible: _ctx.visible,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
        width: "656px",
        height: "474px"
      }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.modalType === 2 ? '编辑备注内容' : '查看备注'), 1)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal(false)))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          }),
          (_ctx.modalType === 2)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: "submit",
                type: "primary",
                onClick: _ctx.handleOk
              }, {
                default: _withCtx(() => [
                  _createTextVNode("保存")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Remarks, {
            ref: "remarksRef",
            "modal-type": _ctx.modalType,
            source: _ctx.source,
            "before-pay-remark-json": encodeURIComponent(_ctx.beforePayRemarkJson),
            "after-pay-remark-json": encodeURIComponent(_ctx.afterPayRemarkJson),
            "payment-remark": _ctx.paymentRemark,
            "order-no": _ctx.orderNo,
            onCloseModal: _ctx.closeModal
          }, null, 8, ["modal-type", "source", "before-pay-remark-json", "after-pay-remark-json", "payment-remark", "order-no", "onCloseModal"])
        ]),
        _: 1
      }, 8, ["visible"]))
    : _createCommentVNode("", true)
}