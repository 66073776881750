
import { defineComponent, computed, ref, reactive, toRaw, watch, onMounted, watchEffect } from 'vue';
import { useCurrentEnterpriseHook } from '@/store';
import { useStore } from 'vuex';
import OilList from './OilList.vue';


export default defineComponent({
  name: 'OilListView',
  components: { OilList },
  setup(props, ctx) {
    useCurrentEnterpriseHook('');
    const store = useStore();
    const { enterprise = {} } = { ...store.state.User };
    const tabs = ref<{label: string, value: number}[]>([]);
    const activeKey = ref();
    if (enterprise.energyTypes.includes(1) || enterprise.energyTypes.includes(2)) {
      tabs.value.push({ label: '加油订单', value: 1 });
      activeKey.value = 1;
    }
    if (enterprise.energyTypes.includes(4)) { 
      tabs.value.push({ label: '充电订单', value: 2 });
      tabs.value.push({ label: '超时占位费订单', value: 3 });
      activeKey.value = activeKey.value || 2;
    }
    if (enterprise.activateCarMaintenanceServices) {
      tabs.value.push({ label: '养车订单', value: 4 });
    }
    return {
      enterprise,
      activeKey,
      tabs,
    };
  },
});
