
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, watch, computed, nextTick, watchEffect, onMounted, getCurrentInstance } from 'vue';
import { settleTree, noEmptyProp, not, dispatchDownload } from '@/utils/illuminate';
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import { createApiUrl } from '@/utils/utils';
import ImageViewer from '@/components/Image/index.vue';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { formatDate } from '@/core/filters';
import { ExceptionMarkEnum } from '@/utils/constants';
import { useCurrentEnterpriseHook } from '@/store';
import { UnwrapNestedRefs } from '@vue/reactivity';
import DetailDrawer from './OilDetail.vue';
import EditRemark from './parts/EditRemark.vue';
import BatchSign from './parts/BatchSign.vue';

const orderStatusMap: Record<string, string> = {
  '-1': '无效',
  0: '待支付',
  1: '已支付',
  2: '退款中',
  3: '部分支付',
  4: '订单取消',
  5: '已退款',
  6: '部分退款',
  7: '退款失败',
};

const statusColorMap: any = {
  1: '#4aab0d',
  2: '#fb881e',
  5: '#333333',
  7: '#f4271c',
};

const oilOrderStatusList = [
  { label: '待支付', value: '0' },
  { label: '已支付', value: '1' },
  { label: '退款中', value: '2' },
  { label: '已退款', value: '5' },
  { label: '退款失败', value: '7' },
];

const chargeOrderStatusList = [
  { label: '启动充电失败', value: -1 },
  { label: '置异常', value: -2 },
  { label: '挂单', value: -3 },
  { label: '初始化', value: 0 },
  { label: '充电中', value: 1 },
  { label: '停止充电', value: 2 },
  { label: '待支付', value: 3 },
  { label: '已支付', value: 4 },
];

const perchOrderStatusList = [
  { label: '进行中', value: 0 },
  { label: '已支付', value: 1 },
  { label: '已退款', value: 2 },
];

// 养车订单 订单状态枚举
const carMaintenanceStatusList = [
  { label: '待支付', value: 0 },
  { label: '支付成功', value: 1 },
  { label: '支付失败', value: 2 },
  { label: '已取消', value: 3 },
  { label: '已退款', value: 4 },
  { label: '退款中', value: 5 },
  { label: '退款失败', value: 6 },
  { label: '申请退款', value: 7 },
  { label: '待评价', value: 8 },
  { label: '已完成', value: 9 },
];

const defaultFilterParams = {
  userCode: undefined,
  createTime: [moment(), moment()],
  payTime: [],
  orderStatus: [],
  payType: '',
  exceptionMark: '',
  departmentId: undefined,
  licensePlateNo: '',
  orderNoOrOrderOilNo: '',
  vin: '',
  paymentRemark: '',
};

const ranges = {
  今天: [moment(), moment()],
  近一周: [moment().subtract('days', 6), moment()],
  当月: [moment().startOf('month'), moment()],
  近一个月: [moment().subtract('days', 29), moment()],
  近两个月: [moment().subtract('days', 59), moment()],
  近三个月: [moment().subtract('days', 89), moment()],
};

export default defineComponent({
  name: 'OilListView',
  components: {
    DetailDrawer,
    EditRemark,
    ImageViewer,
    BatchSign,
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    activeKey: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    useCurrentEnterpriseHook('');
    const store = useStore();
    const authority = ref(store.state.User.authority);
    let filterParams = reactive<{
      userCode: string | undefined;
      createTime: Moment[];
      payTime: Moment[];
      orderStatus: Array<string|number>;
      payType: string | undefined;
      exceptionMark: string | undefined;
      departmentId: number | undefined;
      licensePlateNo: string;
      vin: string | undefined;
      orderNoOrOrderOilNo: string | undefined;
      paymentRemark: string | undefined;
    }>({
      ...defaultFilterParams,
    });
    const checkAllChange = (val: Array<string|number>) => {
      filterParams.orderStatus = val;
    };
    /** ----------------------------------------------------- 表单 ---------------------------------------------- */
    const orderStatusList = ref(props.type === 1 ? oilOrderStatusList : props.type === 2 ? chargeOrderStatusList : props.type === 3 ? perchOrderStatusList : carMaintenanceStatusList);
    let commonSearchList = reactive([
      { 
        label: '员工',
        name: 'userCode',
        type: 'member',
        placeholder: '员工',
        status: 'ALL',
      },
      { 
        label: '公司',
        name: 'currentEnterpriseId',
        type: 'currentEnterprise',
        withDefaultAll: authority.value === 0,
      },
      { 
        label: '部门',
        name: 'departmentId',
        type: 'tree_select',
        allowClear: true,
        showSearch: true,
        showLine: true,
        placeholder: '部门名称',
        searchPlaceholder: '请选择', 
        treeNodeFilterProp: 'title',
        treeData: [],
      },
      { 
        label: '订单状态',
        name: 'orderStatus',
        type: 'multiple_select',
        placeholder: '选择订单状态',
        allowClear: true, 
        fieldNames: { label: 'label', value: 'value' },
        sel_data: orderStatusList.value,
        mode: 'multiple',
        indeterminate: false,
        checkAll: false,
        maxTagCount: 4,
        maxTagPlaceholder: '...',
        onCheckAllChange: checkAllChange,
      },
      { 
        label: '下单时间',
        name: 'createTime',
        type: 'rangepicker',
        format: 'YYYY-MM-DD',
        placeholder: ['起始时间', '结束时间'],
        ranges,
        disabledDate: (current: Moment) => current && current > moment().endOf('day'),
        width: '230px',
      },
    ]);

    const oilSearchList = [ 
      { 
        label: '订单号',
        name: 'orderNoOrOrderOilNo',
        type: 'input',
        placeholder: '订单号',
        allowClear: true, 
      },
      { 
        label: '车牌号',
        name: 'licensePlateNo',
        type: 'input',
        allowClear: true,
        placeholder: '车牌号',
      }, 
      { 
        label: '车牌识别代号',
        name: 'vin',
        type: 'input',
        placeholder: '车牌识别代号',
        allowClear: true, 
      },
      { 
        label: '支付方式',
        name: 'payType',
        type: 'select',
        placeholder: '支付方式',
        allowClear: true, 
        fieldNames: { label: 'label', value: 'value' },
        sel_data: [
          { label: '全部', value: '' },
          { label: '企业支付(员工)', value: 9 },
          { label: '企业支付(车辆)', value: 8 },
          { label: '个人支付(微信)', value: 4 },
          { label: '个人支付(支付宝)', value: 3 },
          { label: '预付卡支付', value: 14 },
          { label: '三方代付', value: 16 },
          { label: '联通wow支付', value: 15 },
        ],
      },
      { 
        label: '员工折扣卡',
        name: 'discountCardId',
        type: 'input',
        placeholder: '员工折扣卡',
        allowClear: true, 
      },
      { 
        label: '备注内容',
        name: 'paymentRemark',
        type: 'input',
        placeholder: '备注内容',
        allowClear: true, 
      },
      ...commonSearchList,
    ];

    const chargeSearchList = [
      { 
        label: '车牌号',
        name: 'licensePlateNo',
        type: 'input',
        allowClear: true,
        placeholder: '车牌号',
      }, 
      ...commonSearchList,
    ];

    const prechSearchList = [
      ...commonSearchList,
    ];
    // 养车订单查询条件
    const carSearchList = ref([
      { 
        label: '订单号',
        name: 'orderNo',
        type: 'input',
        placeholder: '订单号',
        allowClear: true, 
      },
      { 
        label: '企业名称',
        name: 'queryEnterpriseId',
        type: 'currentEnterprise',
        withDefaultAll: authority.value === 0,
      },
      { 
        label: '员工手机号',
        name: 'phone',
        type: 'input',
        placeholder: '员工手机号',
        allowClear: true, 
      },
      { 
        label: '订单状态',
        name: 'orderStatus',
        type: 'multiple_select',
        placeholder: '选择订单状态',
        allowClear: true, 
        fieldNames: { label: 'label', value: 'value' },
        sel_data: orderStatusList.value,
        mode: 'multiple',
        indeterminate: false,
        checkAll: false,
        maxTagCount: 4,
        maxTagPlaceholder: '...',
        onCheckAllChange: checkAllChange,
      },
      { 
        label: '核销码状态',
        name: 'verificationStatus',
        type: 'select',
        placeholder: '选择核销码状态',
        allowClear: true, 
        fieldNames: { label: 'name', value: 'value' },
        sel_data: [],
      },
      { 
        label: '下单时间',
        name: 'createTime',
        type: 'rangepicker',
        format: 'YYYY-MM-DD',
        placeholder: ['起始时间', '结束时间'],
        ranges,
        disabledDate: (current: Moment) => current && current > moment().endOf('day'),
        width: '230px',
      },
      { 
        label: '支付时间',
        name: 'payTime',
        type: 'rangepicker',
        format: 'YYYY-MM-DD',
        placeholder: ['起始时间', '结束时间'],
        ranges,
        disabledDate: (current: Moment) => current && current > moment().endOf('day'),
        width: '230px',
      },
    ]);
    const collectInfos = reactive<{
      orderTotal: number,
      totalOrderAmount: number,
      enterprisePayRealAmount: number,
      individualPayRealAmount: number,
      totalLitre: number,
    }>({
      orderTotal: 0,
      totalOrderAmount: 0.00,
      enterprisePayRealAmount: 0.00,
      individualPayRealAmount: 0.00,
      totalLitre: 0.00,
    });
    const isShowPayDiscountAmount = ref(-1);
    const isShowDiscountCardReduceAmount = ref(-1);
    const isShowThirdPartyCouponDiscountAmount = ref(-1);
    const isShowDiscountCardId = ref(-1);
    const isShowPrepaidCardCardNos = ref(-1);
    // 加油订单表头
    const oilTableColumns: TableColumn[] = [];
    // 充电订单表头
    const chargeTableColumns: TableColumn[] = [
      { title: '订单号', dataIndex: 'orderNo', width: '250px', align: 'center' },
      { title: '企业名称', dataIndex: 'enterpriseName', width: '220px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '员工姓名', dataIndex: 'userName', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '员工手机号', dataIndex: 'phone', width: '150px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '下单时间', dataIndex: 'createDate', width: '200px', customRender: ({ text }) => formatDate(text), align: 'center' },
      { title: '支付时间', dataIndex: 'payTime', width: '200px', align: 'center', customRender: ({ text }) => formatDate(text) },
      { title: '支付方式', dataIndex: 'payType', width: '120px', align: 'center', slots: { customRender: 'payType' } },
      { title: '订单状态', dataIndex: 'orderStatusDesc', align: 'center', width: '150px' },
      { title: '备注', dataIndex: 'chargePaymentRemark', width: '210px', align: 'center', ellipsis: true, slots: { customRender: 'chargePaymentRemark' } },
      { title: '车牌号', dataIndex: 'plateNumber', width: '150px', align: 'center' },
      { title: '订单总金额', dataIndex: 'totalOrderAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '充电总金额', dataIndex: 'chargingAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '服务费金额', dataIndex: 'serviceAmount', width: '150px', customRender: ({ text }) => +(text || 0).toFixed(2), align: 'center' },
      { title: '企业实付金额', dataIndex: 'totalRealAmount', width: '150px', customRender: ({ text }) => text && (+text).toFixed(2), align: 'center' },
      { title: '企业卡优惠', dataIndex: 'enterpriseCardAmount', width: '150px', customRender: ({ text }) => text && (+text).toFixed(2), align: 'center' },
      { title: '最终实付', dataIndex: 'payRealAmount', width: '150px', customRender: ({ text }) => text && (+text).toFixed(2), align: 'center' },
      { title: '度数', dataIndex: 'chargingDegree', width: '80px', align: 'center' },
      { title: '能源类型', dataIndex: 'energyType', width: '80px', align: 'center', slots: { customRender: 'energyType' } },
      { title: '站点名称', dataIndex: 'stationName', width: '160px', align: 'center' },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right', align: 'center' },
    ];
    // 超时占位费订单表头
    const prechTableColumns: TableColumn[] = [
      { title: '订单号', dataIndex: 'orderNo', width: '250px', align: 'center' },
      { title: '企业名称', dataIndex: 'enterpriseName', width: '220px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '员工姓名', dataIndex: 'userName', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '员工手机号', dataIndex: 'phone', width: '150px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '下单时间', dataIndex: 'createDate', width: '200px', customRender: ({ text }) => formatDate(text), align: 'center' },
      { title: '支付时间', dataIndex: 'payTime', width: '200px', align: 'center', customRender: ({ text }) => formatDate(text) },
      { title: '支付方式', dataIndex: 'payType', width: '120px', align: 'center', slots: { customRender: 'payType' } },
      { title: '订单状态', dataIndex: 'orderStatusDesc', align: 'center', width: '150px' },
      { title: '订单总金额', dataIndex: 'totalOrderAmount', width: '150px', customRender: ({ text }) => (+text).toFixed(2), align: 'center' },
      { title: '企业实付金额', dataIndex: 'totalRealAmount', width: '150px', customRender: ({ text }) => text && (+text).toFixed(2), align: 'center' },
      { title: '能源类型', dataIndex: 'energyType', width: '80px', align: 'center', slots: { customRender: 'energyType' } },
      { title: '站点名称', dataIndex: 'stationName', width: '160px', align: 'center' },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right', align: 'center' },
    ];
    // 养车订单表头
    const carTableColumns: TableColumn[] = [
      { title: '订单号', dataIndex: 'orderNo', width: '220px', align: 'center' },
      { title: '企业ID', dataIndex: 'enterpriseId', width: '100px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '企业名称', dataIndex: 'enterpriseName', width: '150px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '员工姓名', dataIndex: 'userName', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '员工手机号', dataIndex: 'phone', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '商品名称', dataIndex: 'productName', width: '100px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '商品价格', dataIndex: 'productPrice', width: '100px', customRender: ({ text }) => (+text).toFixed(2), align: 'center' },
      { title: '订单金额', dataIndex: 'totalOrderAmount', width: '100px', customRender: ({ text }) => (+text).toFixed(2), align: 'center' },
      { title: '员工折扣卡抵扣金额', dataIndex: 'discountCardReduceAmount', width: '150px', customRender: ({ text }) => (+text).toFixed(2), align: 'center' },
      { title: '员工折扣卡卡号', dataIndex: 'discountCardId', width: '120px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '实付金额', dataIndex: 'payRealAmount', width: '100px', customRender: ({ text }) => text && (+text).toFixed(2), align: 'center' },
      { title: '支付方式', dataIndex: 'payTypeStr', width: '100px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '下单时间', dataIndex: 'createDate', width: '160px', customRender: ({ text }) => formatDate(text), align: 'center' },
      { title: '支付时间', dataIndex: 'payTime', width: '160px', align: 'center', customRender: ({ text }) => formatDate(text) },
      { title: '订单状态', dataIndex: 'orderStatusStr', width: '100px', align: 'center' },
      { title: '核销码状态', dataIndex: 'verificationStatusStr', width: '100px', align: 'center' },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right', align: 'center' },
    ];
    const tableColumns = ref<any[]>([]);
    const allTableColumns: any[] = [];
    const checkedOptions = ref<any[]>([]); // 所有的复选框
    const checkedList = ref<any[]>([]); // 选中的复选框
    const tableRef = ref<any>(null);
    const orderMarkOpen = ref(false);
    let configs: any[] = [];
    const getCarVerificationStatusEnum = async () => {
      try {
        const res = await request.get(createApiUrl('/newlinkSass/order/carMaintenance/getCarVerificationStatusEnum'));
        if (res.code === 200) {
          carSearchList.value.forEach(i => {
            if (i.name === 'verificationStatus') {
              i.sel_data = res.data || [];
            }
          });
        }
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };
    const init = () => {
      try {
        if (props.type === 4) { // 养车订单获取核销码状态枚举
          tableColumns.value = carTableColumns;
          return getCarVerificationStatusEnum();
        }
        Promise.all([
          request.post(createApiUrl('/newlinkSass/order/getOrderExceptionMarkOpen'), {}), // 获取是否允许打标记
          request.post(createApiUrl('/newlinkSass/order/getOrderSelectConfig'), {}), // 获取表头配置
        ]).then(res => {
          const [res1, res2] = res;
          // true 展示标记状态相关 false 不展示标记状态相关
          orderMarkOpen.value = Boolean(res1.data);
          configs = res2.data || [];
          const operation: TableColumn = { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right', align: 'center' };
          configs.forEach((item: any, index) => {
            let im: TableColumn = { title: item.name, dataIndex: item.field, width: '150px', customRender: ({ text }: any) => ((text === '' || text === null || text === undefined) ? '---' : text), align: 'center', ellipsis: true };
            if (item.field === 'orderNo') {
              im = { ...im, width: '250px' };
            }
            if (item.field === 'invoiced') { // 已开发票
              im = { ...im, customRender: ({ text }: any) => (text ? '是' : '否') };
            }
            if (item.field === 'accountDeptNames') { // 部门
              im = { title: item.name, slots: { customRender: 'accountDeptNames' }, width: '200px', align: 'center', ellipsis: true };
            }
            if (item.field === 'orderStatus') { // 订单状态
              im = { title: item.name, dataIndex: 'orderStatus', width: '120px', slots: { customRender: 'order-status' }, align: 'center', ellipsis: true };
            }
            if (item.field === 'paymentRemark') { // 备注
              im = { title: item.name, dataIndex: 'paymentRemark', width: '210px', align: 'center', ellipsis: true, slots: { customRender: 'paymentRemark' } };
            }
            if (item.field === 'payDiscountAmount') { // 企业卡优惠
              isShowPayDiscountAmount.value = item.selected;
            }
            if (item.field === 'discountCardReduceAmount') { // 员工折扣卡优惠
              isShowDiscountCardReduceAmount.value = item.selected;
            }
            if (item.field === 'thirdPartyCouponDiscountAmount') { // 三方卡券抵扣金额
              isShowThirdPartyCouponDiscountAmount.value = item.selected;
            }
            if (item.field === 'discountCardId') { // 员工折扣卡卡号
              isShowDiscountCardId.value = item.selected;
            }
            if (item.field === 'prepaidCardCardNos') { // 汇元预付卡卡号
              isShowPrepaidCardCardNos.value = item.selected;
            }
            // -1 不可展示 0 未被选择 1 已被选择
            if (item.selected === 1) {
              allTableColumns.push(im);
              oilTableColumns.push(im);
              checkedOptions.value.push(item.name);
              checkedList.value.push(item.name);
            }
            if (item.selected === 0) {
              allTableColumns.push(im);
              checkedOptions.value.push(item.name);
            }
          });
          allTableColumns.push(operation);
          oilTableColumns.push(operation);
          let columns = props.type === 1 ? oilTableColumns : (props.type === 2 ? chargeTableColumns : prechTableColumns);
          const flag = columns.findIndex(item => item.dataIndex === 'exceptionMark');
          if (!orderMarkOpen.value) columns = columns.filter(item => item.dataIndex !== 'exceptionMark');
          else if (props.type !== 1 && flag === -1) columns.splice(columns.length - 2, 0, { title: '标记状态', dataIndex: 'exceptionMark', width: '120px', customRender: ({ text }) => ExceptionMarkEnum[text], align: 'center' });
          tableColumns.value = columns;
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };
    init();
    let selectState: UnwrapNestedRefs<{
      selectedRowKeys: any[];
      selectedRows: any[];
      onChange: (selectedRowKeys: any[], selectedRows: any[]) => void,
    }>;
    const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      selectState.selectedRows = selectedRows;
      selectState.selectedRowKeys = selectedRowKeys;
    };
    selectState = reactive({
      selectedRowKeys: [],
      selectedRows: [],
      onChange: onSelectChange,
    });
    const batchVisible = ref(false);
    // 批量标记
    const handleBatchSign = () => {
      if (!selectState.selectedRows.length) return message.warning('请选择需要标记的订单！');
      batchVisible.value = true;
    };
    // 关闭批量标记弹窗
    const closeModal = () => {
      batchVisible.value = false;
    };
    const searchParams = ref({}); // 查询参数
    const carTableData = ref([]); // 养车订单表格数据
    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      try {
        const params: any = {
          ...parameter,
          ...noEmptyProp(not(filterParams, ['payTime', 'createTime', 'orderStatus'])),
          orderType: 1,
        };

        if (filterParams.orderStatus && filterParams.orderStatus.length) {
          params.orderStatus = filterParams.orderStatus.filter(item => (item || item === 0));
        }

        if (!store.state.User.currentEnterpriseId) {
          params.searchLevel = 1;
        }

        if (filterParams.payTime && filterParams.payTime.length) {
          params.payStartTime = moment(filterParams.payTime[0].format('YYYY-MM-DD 00:00:00')).valueOf();
          params.payEndTime = moment(filterParams.payTime[1].format('YYYY-MM-DD 23:59:59')).valueOf();
        }

        if (filterParams.createTime && filterParams.createTime.length) {
          params.createStartTime = moment(filterParams.createTime[0].format('YYYY-MM-DD 00:00:00')).valueOf();
          params.createEndTime = moment(filterParams.createTime[1].format('YYYY-MM-DD 23:59:59')).valueOf();
        }
        searchParams.value = params;
        if (props.type === 1) {
          Promise.all([
            request.get(createApiUrl('/newlinkSass/order/find-sass-statistic-order-info'), { params }),
            request.get(createApiUrl('/newlinkSass/order/find-order-list-v3'), { params }),
          ]).then(res => {
            const [collect, list] = res;
            collectInfos.orderTotal = collect.data.orderTotal || 0;
            collectInfos.totalOrderAmount = collect.data.totalOrderAmount || 0.00;
            collectInfos.enterprisePayRealAmount = collect.data.enterprisePayRealAmount || 0.00;
            collectInfos.individualPayRealAmount = collect.data.individualPayRealAmount || 0.00;
            collectInfos.totalLitre = collect.data.totalLitre || 0.00;           
            resolve({ ...list.data });
          });
        } else if (props.type === 4) {
          request.post(createApiUrl('/newlinkSass/order/carMaintenance/queryByPage'), params)
            .then((res: any) => {
              carTableData.value = res.data.list;
              resolve({ ...res.data });
            });
        } else {
          const url = props.type === 2 ? '/order/findElectricityOrderPage' : '/order/findOvertimeOccupyOrderPage';
          request.get(createApiUrl(`/newlinkSass${url}`), { params })
            .then((res: any) => {
              resolve({ ...res.data });
            });
        }
      } catch (e: Error & any) {
        return message.error(e.message || '导出报错');
      }
    });

    const judgeAmount = (val: any) => {
      const cur = val.toString().replace(/,/g, '');
      if (!Number(cur)) return false;
      return true;
    };

    // 重置
    const onRefreshTable = async (val?: any) => {
      if (val) { // 清空表格勾选
        selectState.selectedRows = [];
        selectState.selectedRowKeys = [];
      }
      await nextTick();
      if (filterParams.orderNoOrOrderOilNo || filterParams.userCode) return tableRef.value.refresh(true);
      if (!filterParams.createTime || !filterParams.createTime.length) return message.warning(`${props.type === 1 ? '订单号、' : ''}员工、下单时间需选择其中一项才能查询哦～`);
      if (filterParams.createTime && filterParams.createTime.length > 1) {
        const diff = filterParams.createTime[1].diff(filterParams.createTime[0], 'days') > 94;
        if (diff) return message.warning('下单时间范围最多支持90天～');
      }
      tableRef.value.refresh(true);
    };

    // = ---------------------------- = 查看详情 = ---------------------------- =
    const detailRef = ref(null as any);
    const onViewItemDetail = (record: any) => {
      detailRef.value.view(record.orderNo, record.enterpriseId, record);
    };

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);
    const exportParams = reactive<{
      timeRange: Moment[];
      licensePlateNo: string;
      vin: string | undefined;
      orderStatus: string[];
      departmentId: number | undefined;
    }>({
      timeRange: [],
      licensePlateNo: '',
      vin: '',
      orderStatus: [],
      departmentId: undefined,
    });
    // 导出调接口
    const dowmloadExcel = async (params: Record<string, any>) => {
      let res: any;
      try {
        switch (props.type) {
          case 2:
            res = await request.post(createApiUrl('/newlinkSass/excel/down/electricity_order_list'), { ...params }, { responseType: 'blob' });
            break;
          case 3:
            res = await request.post(createApiUrl('/newlinkSass/excel/down/occupy_order_list'), { ...params }, { responseType: 'blob' });
            break;
          case 4:
            res = await request.post(createApiUrl('/newlinkSass/excel/export/carMaintenance'), { ...params }, { responseType: 'blob' });
            break;
          default:
            res = await request.get(createApiUrl('/newlinkSass/excel/down/account/order/detail'), { responseType: 'blob', params });
            break;
        }
      } catch (e: Error & any) {
        return message.error(e.message || '导出报错');
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      dispatchDownload(props.type === 1 ? '加油订单列表.zip' : props.type === 2 ? '充电订单列表.xls' : props.type === 3 ? '超时占位费订单列表.xls' : '养车订单列表.zip', url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };
    const onExport = () => {
      if (props.type === 4) {
        if (!carTableData.value.length) return message.info('无可导出订单，请查询');
        return dowmloadExcel(searchParams.value);
      }
      Object.assign(exportParams, {
        timeRange: [
          moment().add(-90, 'day'),
          moment(),
        ],
        licensePlateNo: filterParams.licensePlateNo,
        vin: filterParams.vin,
        orderStatus: [],
        departmentId: filterParams.departmentId,
      });
      confirmVisible.value = true;
    };

    let firstSelectTime: Moment | null;
    // 动态禁止超过九十天的日期 📅
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
      if (!firstSelectTime) return false;
      return Math.abs(firstSelectTime.valueOf() - currentDate.valueOf()) > 7776000000;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };
    const exporting = ref(false);
    const onConfirmExport = async () => {
      const params: Record<string, any> = {};
      if (exportParams.orderStatus.length) {
        params.orderStatus = props.type === 1 ? exportParams.orderStatus.join(',') : exportParams.orderStatus;
      }
      if (exportParams.timeRange.length) {
        params.createStartTime = moment(exportParams.timeRange[0].format('YYYY-MM-DD 00:00:00')).valueOf();
        params.createEndTime = moment(exportParams.timeRange[1].format('YYYY-MM-DD 23:59:59')).valueOf();
      }
      if (!store.state.User.currentEnterpriseId) {
        params.searchLevel = 1; // 全部
      }
      params.departmentId = exportParams.departmentId || ''; // 部门
      params.licensePlateNo = exportParams.licensePlateNo;
      params.vin = exportParams.vin;
      dowmloadExcel(params);
    };
    /* = -------------------------------------------------- = 加载搜索用的部门树 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    const loadEnterpriseDepartment = async (val:any) => {
      filterParams.departmentId = undefined; // 清空部门
      exportParams.departmentId = undefined;
      // 公司查询全部 部门为空
      if (val === '') {
        departmentList.value = [];
        return;
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      departmentList.value = settleTree(res.data.departmentList,
        { key: 'id', title: 'departmentName', value: 'id' },
        'departmentList');
      commonSearchList = commonSearchList.map((item:any) => {
        if (item.name === 'departmentId') item.treeData = departmentList.value;
        return item;
      });
    };

    /* -------------------------------------------------------- 备注 -----------------------------------------------------------------------*/
    const editRemarkRef = ref();
    const onEditRemark = async (record: any, typ: Number, source: string) => {
      if (source === 'charge') {
        editRemarkRef.value.showModal(record.electricityBeforePayRemarkConfig, record.electricityAfterPayRemarkConfig, '', record.thirdTransNo, typ, source);
        return;
      }
      try {
        const res = await request.get(
          createApiUrl('/newlinkSass/order/find-order-detail'),
          { params: { orderNo: record.orderNo, enterpriseId: record.enterpriseId } },
        );
        if (res.code === 200) {
          editRemarkRef.value.showModal(res.data.orderInfo.beforePayRemarkJson, res.data.orderInfo.afterPayRemarkJson, res.data.orderInfo.paymentRemark, res.data.orderInfo.orderNo, typ, source);
        }
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };
    const updateRemark = () => {
      tableRef.value.refresh(false);
    };
    const remarkPicVisible = ref<boolean>(false);
    const payRemarkImageList = ref([]);
    const setRemarkPicVisible = (record: any): void => {
      remarkPicVisible.value = true;
      payRemarkImageList.value = record?.payRemarkImageList || [];
    };

    watch(() => filterParams.userCode, onRefreshTable);

    watch(() => store.state.User.currentEnterpriseId, () => {
      loadEnterpriseDepartment(store.state.User.currentEnterpriseId);
    });
    // 初始查询表格
    if (props.activeKey === props.type) {
      filterParams = Object.assign(filterParams, { ...defaultFilterParams });
      onRefreshTable();
    }
    const searchList = ref<any[]>([]);
    watchEffect(() => {
      let search: any[] = props.type === 1 ? oilSearchList : (props.type === 2 ? chargeSearchList : props.type === 3 ? prechSearchList : carSearchList.value);
      if (orderMarkOpen.value) {
        const flag = search.findIndex(item => item.name === 'exceptionMark');
        if (flag === -1) {
          search.splice(search.length - 1, 0, { 
            label: '标记状态',
            name: 'exceptionMark',
            type: 'select',
            placeholder: '标记状态',
            allowClear: true, 
            fieldNames: { label: 'label', value: 'value' },
            sel_data: [
              { label: '全部', value: '' },
              { label: '待标记', value: -1 },
              { label: '正常', value: 0 },
              { label: '异常', value: 1 },
            ],
          });
        }
      } else {
        search = search.filter((item:any) => item.name !== 'exceptionMark');
      }
      searchList.value = search;
    });
    watch(() => props.activeKey, (val: number, old: number) => {
      if (val === props.type) { // tabs 切换刷新表格
        filterParams = Object.assign(filterParams, { ...defaultFilterParams });
        searchList.value = searchList.value.map((item:any) => {
          if (item.name === 'orderStatus') return { ...item, indeterminate: false, checkAll: false };
          return item;
        });
        onRefreshTable();
      }
    });
    const handleChange = async (val: string[]) => {
      val.push('操作');
      tableColumns.value = allTableColumns.filter(i => val.indexOf(i.title) !== -1);
      try {
        const params = {
          configs: JSON.parse(JSON.stringify(configs)),
        };
        params.configs.forEach((item: any) => {
          if (item.selected !== -1) {
            item.selected = val.indexOf(item.name) !== -1 ? 1 : 0;
          }
        });
        // 保存用户勾选的记录
        request.post(createApiUrl('/newlinkSass/order/updateOrderSelectConfig'), params);
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };

    const showRemark = (record: Record<string, any>) => {
      const beforeRemark = JSON.parse(record.electricityBeforePayRemarkConfig || '{}') || {};
      const afterRemark = JSON.parse(record.electricityAfterPayRemarkConfig || '{}') || {};
      return beforeRemark.remarkFlag || afterRemark.remarkFlag;
    };

    onMounted(() => {
      if (props.type === 1) instance?.$sensors.track('es_saas_order_center_gas_page_view', { page_name: '订单管理_加油订单_浏览' });
      if (props.type === 2) instance?.$sensors.track('es_saas_order_center_charge_page_view', { page_name: '订单管理_充电订单_浏览' });
      if (props.type === 3) instance?.$sensors.track('es_saas_order_center_timeout_page_view', { page_name: '订单管理_超时站位费订单_浏览' });
      if (props.type === 4) instance?.$sensors.track('es_saas_order_center_maintenance_page_view', { page_name: '订单管理_养车订单_浏览' });
    });

    return {
      orderMarkOpen,
      batchVisible,
      closeModal,
      handleBatchSign,
      selectState,
      handleChange,
      checkedList,
      authority,
      departmentList,
      collectInfos,

      orderSourceList: [
        { label: '全部', value: '' },
        { label: '员工余额支付', value: '2' },
      ],
      orderStatusList,
      searchList,
      orderStatusMap,
      statusColorMap,

      filterParams,
      tableColumns,
      checkedOptions,
      loadTableData,
      tableRef,
      onRefreshTable,
      updateRemark,
      remarkPicVisible,
      setRemarkPicVisible,

      confirmVisible,
      onExport,
      exportParams,

      isDateDisable,
      onOpenChange,
      onCalendarChange,

      exporting,
      onConfirmExport,

      detailRef,
      onViewItemDetail,
      ranges,
      judgeAmount,
      onEditRemark,
      payRemarkImageList,
      editRemarkRef,
      isShowPayDiscountAmount,
      isShowDiscountCardReduceAmount,
      isShowThirdPartyCouponDiscountAmount,
      isShowDiscountCardId,
      isShowPrepaidCardCardNos,
      showRemark,
    };
  },
});
