
import { defineComponent, ref, h, computed } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { useState } from '@/store';
import { useStore } from 'vuex';
import ImageViewer from '@/components/Image/index.vue';
import { createApiUrl, isEmpty } from '@/utils/utils';
import { request } from '@/utils/request';
import { orderSourceMap } from '@/utils/constants';
import { formatDate } from '@/core/filters';
import EditRemark from './parts/EditRemark.vue';
import BatchSign from './parts/BatchSign.vue';
import Remarks from './parts/Remarks.vue';

const defaultMarks = [
  {
    createTime: '',
    nickName: '',
    mobile: '',
    exceptionMark: -1,
    reason: '',
  },
];

export default defineComponent({
  name: 'OilDetailDrawer',
  components: { EditRemark, ImageViewer, BatchSign, Remarks },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: '',
    },
    orderMarkOpen: {
      type: Boolean,
      default: false,
    },
    isShowPayDiscountAmount: { // 是否展示企业卡优惠
      type: Number,
      default: -1,
    },
    isShowDiscountCardReduceAmount: { // 是否展示员工折扣卡优惠
      type: Number,
      default: -1,
    },
    isShowThirdPartyCouponDiscountAmount: { // 是否展示三方卡券抵扣金额
      type: Number,
      default: -1,
    },
    isShowDiscountCardId: { // 是否展示员工折扣卡卡号
      type: Number,
      default: -1,
    },
    isShowPrepaidCardCardNos: { // 是否展示汇元预付卡卡号
      type: Number,
      default: -1,
    },
  },
  emits: ['complete'],
  setup(props, ctx) {
    const store = useStore();
    const authority = ref(store.state.User.authority);
    const modelVisible = ref(false);
    const record = ref<any>({});
    const state = useState();
    const detailData = ref<any>({
      orderInfo: {
        createDate: '',
        num: 0,
        orderNo: '',
        orderStatus: 1,
        orderStatusStr: '',
        jobNo: '',
        payDate: '',
        phone: '',
        userName: '',
        vehicleNo: '',
        paymentRemark: '',
        exceptionMark: null,
        marks: [
          {
            createTime: '',
            nickName: '',
            mobile: '',
            exceptionMark: -1,
            reason: '',
          },
        ],
        beforePayRemarkJson: '',
        afterPayRemarkJson: '',
      },
      orderPayInfo: {
        amount: '',
        num: '',
        orgPrice: '',
        payType: '',
        payTypeStr: '',
        priceGun: '',
        rules: '',
        totalDiscountAmount: '',
        totalOrderAmount: '',
        totalRealAmount: '',
        enterprisePayRealAmount: '',
        individualPayRealAmount: '',
        payDiscountAmount: '',
        discountTypeStr: '',
        chargingAmount: '',
        serviceAmount: '',
        occupyAmount: '',
        overtimeOccupyOrderNo: '',
        weight: '',
      },
      orderStationInfo: {
        areas: '',
        gasName: '',
        oilName: '',
        energyType: '',
      },
      vehicle:	{
        licensePlate: '',
        vehicleBrand:	'',
        vehicleBrandName:	'',
        vehicleBrandLogo:	'',
        vehicleColour: '',
        vehicleColourValue: '',
        vehicleId: '',
        vehicleNo: '',
        vehicleUrl: '',
      },
      enterpriseName: '',
    });
    const chargeDetailData = ref<any>({});
    const perchDetailData = ref<any>({});
    const companyId = ref();
    const orderValue = ref<any>('');

    const loadData = async (orderNo: string, enterpriseId: number) => {
      let params = {};
      if (props.type === 4) {
        params = {
          id: record.value.id,
        };
      } else {
        params = {
          orderNo, enterpriseId,
        };
      }
      let res: any;
      try {
        const url = props.type === 1 ? '/order/find-order-detail' : (props.type === 2 ? '/order/findElectricityOrderDetail' : props.type === 3 ? '/order/findOvertimeOccupyOrderDetail' : '/order/carMaintenance/getById');
        res = await request.get(
          createApiUrl(`/newlinkSass${url}`),
          { params },
        );
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      switch (props.type) {
        case 2: 
          chargeDetailData.value = { ...record.value, ...res.data };
          break;
        case 3: 
          perchDetailData.value = { ...record.value, ...res.data };
          break;
        default:
          detailData.value = res.data;
      }
    };

    const getCarTypeStr = computed(() => {
      if (chargeDetailData.value.carType) return '私车';
      if (chargeDetailData.value.carType === 0) return '公车';
      return '--';
    });

    // = ---------------------------- = 查看信息入口 = ---------------------------- =
    const visible = ref(false);
    const exceptionMark = ref(0);
    const reason = ref('');
    const signVisible = ref(false);
    const view = (orderNo: string, enterpriseId: number, item: any) => {
      record.value = item;
      visible.value = true;
      companyId.value = enterpriseId;
      orderValue.value = orderNo;
      loadData(orderNo, enterpriseId);
    };

    const onClose = () => {
      exceptionMark.value = 0;
      reason.value = '';
      visible.value = false;
      ctx.emit('complete');
    };

    const autoInParkImg = ref('');
    function showModelOfPic(url: string) {
      autoInParkImg.value = url;
      modelVisible.value = true;
    }

    /* -------------------------------------------------------- 备注 -----------------------------------------------------------------------*/
    const editRemarkRef = ref();
    const onEditRemark = (record: any, typ: Number, source: string) => {
      if (source === 'charge') {
        editRemarkRef.value.showModal(record.electricityBeforePayRemarkConfig, record.electricityAfterPayRemarkConfig, '', record.thirdTransNo, typ, source);
        return;
      }
      editRemarkRef.value.showModal(record.beforePayRemarkJson, record.afterPayRemarkJson, record.paymentRemark, record.orderNo, typ, source);
    };

    const updateRemark = (source?: string) => {
      if (source === 'charge') {
        loadData(chargeDetailData.value.orderNo, companyId.value);
        return;
      }
      const { orderNo } = detailData.value.orderInfo;
      loadData(orderNo, companyId.value);
    };

    const remarkPicVisible = ref<boolean>(false);
    const payRemarkImageList = ref([]);
    const setRemarkPicVisible = (record: any): void => {
      remarkPicVisible.value = true;
      payRemarkImageList.value = record?.payRemarkImageList || [];
    };
    // 确认提交
    const confirmSubmit = async () => {
      try {
        const res = await request.post(createApiUrl('/newlinkSass/order/markOrderException'), {
          reason: reason.value,
          exceptionMark: exceptionMark.value, // 订单异常标志 0--正常 1--异常
          orders: [record.value.orderNo],
          type: props.type,
        });
        if (res && res.code === 200) message.success('操作成功');
        onClose();
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };
    
    const marksInfos = computed(() => {
      switch (props.type) {
        case 1:
          return {
            exceptionMark: detailData.value.orderInfo.exceptionMark,
            marks: detailData.value.orderInfo.marks || defaultMarks,
          };
        case 2: 
          return {
            exceptionMark: chargeDetailData.value.exceptionMark,
            marks: chargeDetailData.value.marks || defaultMarks,
          };
        case 3:
          return {
            exceptionMark: perchDetailData.value.exceptionMark,
            marks: perchDetailData.value.marks || defaultMarks,
          };
        default: 
          return {
            exceptionMark: null,
            marks: defaultMarks,
          };
      }
    });

    const getExceptionMark = computed(() => {
      switch (marksInfos.value.marks?.[0].exceptionMark) {
        case 1:
          return '异常';
        case 0:
          return '正常';
        default:
          return '未知';
      } 
    });

    // 标记
    const handleSign = () => {
      signVisible.value = true;
    };
    // 关闭标记弹框
    const closeModal = (flag = false) => {
      signVisible.value = false;
      if (flag) loadData(orderValue.value, companyId.value);
    };

    const showRemark = (record: Record<string, any>) => {
      const beforeRemark = JSON.parse(record.electricityBeforePayRemarkConfig || '{}') || {};
      const afterRemark = JSON.parse(record.electricityAfterPayRemarkConfig || '{}') || {};
      return beforeRemark.remarkFlag || afterRemark.remarkFlag;
    };

    return {
      closeModal,
      signVisible,
      handleSign,
      confirmSubmit,
      exceptionMark,
      marksInfos,
      reason,
      modelVisible,
      labelCol: { span: 6 },
      wrapperCol: { span: 16, offset: 1 },
      showModelOfPic,
      detailData,
      chargeDetailData,
      perchDetailData,
      orderSourceMap,
      autoInParkImg,
      editRemarkRef,
      authority,
      onEditRemark,

      tableData: [],
      tableColumns: [
        { title: '操作类型', dataIndex: 'mobile' },
        { title: '时间', dataIndex: 'num' },
        { title: '操作人', dataIndex: 'department' },
        { title: '操作内容', dataIndex: 'canPay' },
      ],
      pagination: { total: 100 },
      visible,
      onClose,
      view,
      formatDate,
      updateRemark,
      remarkPicVisible,
      payRemarkImageList,
      setRemarkPicVisible,
      record,
      getExceptionMark,
      getCarTypeStr,
      isEmpty,
      showRemark,
    };
  },
});
