
import { defineComponent, ref } from 'vue';
import Remarks from './Remarks.vue';

export default defineComponent({
  name: 'Modal',
  components: { Remarks },
  emits: ['complete'],
  setup(props, ctx) {
    const visible = ref(false);
    const orderNo = ref<string>('');
    const beforePayRemarkJson = ref('');
    const afterPayRemarkJson = ref('');
    const paymentRemark = ref('');
    const remarksRef = ref<any>();
    const modalType = ref<any>(1); // 1 查看 2 编辑
    const source = ref<string>('oil');
    const showModal = (beforeParams: any, afterParams: any, remarkVal: any, curOrderNo: string, typ: Number, sourceType: string) => {
      modalType.value = typ || 1;
      orderNo.value = curOrderNo;
      visible.value = true;
      beforePayRemarkJson.value = beforeParams;
      afterPayRemarkJson.value = afterParams;
      paymentRemark.value = remarkVal;
      source.value = sourceType;
    };

    const closeModal = (flag = false) => {
      visible.value = false;
      flag && ctx.emit('complete', source.value);
    };

    const handleOk = async () => {
      remarksRef.value.handleOk();
    };

    return {
      orderNo,
      remarksRef,
      showModal,
      closeModal,
      handleOk,
      modalType,
      source,
      visible,
      beforePayRemarkJson,
      afterPayRemarkJson,
      paymentRemark,
    };
  },
});
