
import { defineComponent, ref, toRaw, markRaw, reactive, watchEffect, getCurrentInstance } from 'vue';
import { request } from '@/utils/request';
import { message } from 'ant-design-vue';
import { createApiUrl } from '@/utils/utils';
import { PlusOutlined } from '@ant-design/icons-vue';
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { upload } from '@/utils/cos';

function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
  [key: string]: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[] | any;
}

interface RemarkList {
  remarkName: string,
  remarkType: number,
  remarkValue: any
}
export default defineComponent({
  components: {
    PlusOutlined,
  },
  props: {
    modalType: { // 1 查看 2 编辑
      type: Number,
      default: 1,
    },
    beforePayRemarkJson: { // 支付前备注配置
      type: String,
      default: '',
    },
    afterPayRemarkJson: { // 支付后备注配置
      type: String,
      default: '',
    },
    paymentRemark: {
      type: String,
      default: '',
    },
    orderNo: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      default: 'oil',
    },
  },
  emits: ['closeModal'],
  setup(props, { emit }) {
    const existRemarks = ref(false); // true 运营后台配置了支付备注项 false 运营后台没有配置支付备注项
    let myBeforePayRemarkJson = reactive<any>({});
    let myAfterPayRemarkJson = reactive<any>({});
    const allRemarkListForm = reactive<any>({
      list: [],
    });
    const myPaymentRemark = ref<any>(props.paymentRemark);
    watchEffect(() => {
      myBeforePayRemarkJson = props.beforePayRemarkJson ? JSON.parse(decodeURIComponent(props.beforePayRemarkJson)) || {} : {};
      myAfterPayRemarkJson = props.afterPayRemarkJson ? JSON.parse(decodeURIComponent(props.afterPayRemarkJson)) || {} : {};
      const arr = [...(myBeforePayRemarkJson.remarkList || []), ...(myAfterPayRemarkJson.remarkList || [])];
      arr.forEach((item: any) => {
        if (item.remarkType === 2) {
          item.remarkValue = item.remarkValue ? item.remarkValue.map((i: string, ind: number) => ({ uid: `-${ind + 1}`, status: 'done', url: i })) : [];
        }
        if (item.remarkType === 3) {
          item.remarkValue = item.remarkValue || undefined;
        }
      });
      allRemarkListForm.list = arr;
      existRemarks.value = myBeforePayRemarkJson.remarkFlag || myAfterPayRemarkJson.remarkFlag;
    });
    watchEffect(() => {
      myPaymentRemark.value = props.paymentRemark || '';
    });
    const formRef = ref();
    // 保存
    const handleOk = async () => {
      try {
        const newArr = JSON.parse(JSON.stringify(allRemarkListForm.list));
        newArr.forEach((i: any) => {
          if (i.remarkType === 2) {
            i.remarkValue = i.remarkValue.map((j: any) => j.url);
          }
          if (i.remarkType === 3) {
            i.remarkValue = i.remarkValue || '';
          }
        });
        const b = {
          ...myBeforePayRemarkJson,
          remarkList: newArr.slice(0, (myBeforePayRemarkJson.remarkList || []).length),
        };
        const a = {
          ...myAfterPayRemarkJson,
          remarkList: newArr.slice((myBeforePayRemarkJson.remarkList || []).length),
        };
        let res;
        if (props.source === 'charge') {
          const params = {
            thirdTransNo: props.orderNo, // 订单编号
            electricityBeforePayRemarkConfig: existRemarks.value ? JSON.stringify(b) : '', // 支付前备注配置
            electricityAfterPayRemarkConfig: existRemarks.value ? JSON.stringify(a) : '', // 支付后备注配置
          };
          res = await request.post(createApiUrl('/newlinkSass/order/updateElectricityPaymentRemarkJson'), params);
        } else {
          const params = {
            orderNo: props.orderNo, // 订单编号
            beforePayRemarkJson: existRemarks.value ? JSON.stringify(b) : '', // 支付前备注配置
            afterPayRemarkJson: existRemarks.value ? JSON.stringify(a) : '', // 支付后备注配置
            paymentRemark: myPaymentRemark.value,
          };
          res = await request.post(createApiUrl('/newlinkSass/order/updatePaymentRemarkJson'), params);
        }
        if (res && res.code === 200) message.success('操作成功');
        emit('closeModal', true);
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };
    const previewVisible = ref<boolean>(false);
    const previewImage = ref<string | undefined>('');
    const validatePhoto = async (rule: RuleObject, value: []) => {
      if (!value.length) {
        return Promise.reject('必传');
      }
      return Promise.resolve();
    };
    const handleCancel = () => {
      previewVisible.value = false;
    };
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string;
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };
    // 查看图片
    const handleViewImg = (url: string) => {
      previewImage.value = url;
      previewVisible.value = true;
    };
    // 自定义上传
    const customRequest = async (File: any, ind: number) => {
      const path: any = await upload(File.file);
      allRemarkListForm.list[ind].remarkValue.push(
        {
          uid: File.file.uid,
          status: 'done',
          url: decodeURI(path),
        },
      );
    };
    // 删除回调
    const removeRequest = (file: any, ind: number) => {
      allRemarkListForm.list[ind].remarkValue.splice(allRemarkListForm.list[ind].remarkValue.findIndex((i: FileItem) => i.uid === file.uid), 1);
    };
    return {
      removeRequest,
      customRequest,
      handleViewImg,
      handleOk,
      formRef,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      previewVisible,
      previewImage,
      handleCancel,
      handlePreview,
      validatePhoto,
      existRemarks,
      myPaymentRemark,
      allRemarkListForm,
    };
  },
});
