import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3835676b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "remark__title" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row_sty" }
const _hoisted_5 = { class: "row_sty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
    width: "500px",
    "ok-text": "确认提交",
    onOk: _ctx.handleOk,
    onCancel: _ctx.closeModal
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.signType === 1 ? '修改标记信息' : '批量标记确认'), 1)
    ]),
    default: _withCtx(() => [
      (_ctx.signType === 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, "将订单标记状态修改为"))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, "对选中的" + _toDisplayString(_ctx.selectedRows.length) + "条记录执行", 1)),
      _createVNode(_component_a_radio_group, {
        value: _ctx.exceptionMark,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.exceptionMark) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_4, [
            _createVNode(_component_a_radio, { value: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.signType === 1 ? '标记' : '批量标记') + " 正常", 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("p", _hoisted_5, [
            _createVNode(_component_a_radio, { value: 1 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.signType === 1 ? '标记' : '批量标记') + " 异常", 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["value"]),
      _createElementVNode("p", null, [
        (_ctx.exceptionMark === 1)
          ? (_openBlock(), _createBlock(_component_a_textarea, {
              key: 0,
              value: _ctx.reason,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reason) = $event)),
              "show-count": "",
              maxlength: 120,
              placeholder: "请填写备注内容"
            }, null, 8, ["value"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "onCancel"]))
}