import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cdae964c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "oilOrderList" }
const _hoisted_2 = { class: "oilOrderList__content" }
const _hoisted_3 = {
  key: 1,
  class: "contentBox"
}
const _hoisted_4 = { class: "break-word" }
const _hoisted_5 = { class: "break-word" }
const _hoisted_6 = {
  key: 0,
  class: "break-word"
}
const _hoisted_7 = {
  key: 1,
  class: "break-word"
}
const _hoisted_8 = {
  class: "fl-row",
  style: {"justify-content":"center"}
}
const _hoisted_9 = { class: "paymentRemark" }
const _hoisted_10 = ["title"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "paymentRemark"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_form = _resolveComponent("app-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_app_operates = _resolveComponent("app-operates")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_app_enterprise_input = _resolveComponent("app-enterprise-input")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_detail_drawer = _resolveComponent("detail-drawer")!
  const _component_EditRemark = _resolveComponent("EditRemark")!
  const _component_ImageViewer = _resolveComponent("ImageViewer")!
  const _component_BatchSign = _resolveComponent("BatchSign")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_form, {
        "search-list": _ctx.searchList,
        "search-data": _ctx.filterParams,
        onHandleSearch: _ctx.onRefreshTable
      }, null, 8, ["search-list", "search-data", "onHandleSearch"]),
      _createVNode(_component_app_operates, { mg: "10px 0" }, {
        default: _withCtx(() => [
          (_ctx.type === 1)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "primary",
                style: {"margin-right":"15px"},
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'fapiao.issue_houpiao.list' })))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("开发票")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            style: {"margin-right":"15px"},
            onClick: _ctx.onExport
          }, {
            default: _withCtx(() => [
              _createTextVNode("导出")
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.orderMarkOpen)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                class: "batch_sign",
                type: "primary",
                onClick: _ctx.handleBatchSign
              }, {
                default: _withCtx(() => [
                  _createTextVNode("批量标记")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.type === 1)
        ? (_openBlock(), _createBlock(_component_a_checkbox_group, {
            key: 0,
            value: _ctx.checkedList,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedList) = $event)),
            options: _ctx.checkedOptions,
            class: "table_top_checkbox",
            onChange: _ctx.handleChange
          }, null, 8, ["value", "options", "onChange"]))
        : _createCommentVNode("", true),
      (_ctx.type == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _withDirectives(_createElementVNode("div", { class: "collect-item" }, "共查询到: " + _toDisplayString(_ctx.collectInfos.orderTotal) + " 条记录", 513), [
              [_vShow, _ctx.judgeAmount(_ctx.collectInfos.orderTotal)]
            ]),
            _withDirectives(_createElementVNode("div", { class: "collect-item" }, "总加油金额: " + _toDisplayString(_ctx.collectInfos.totalOrderAmount) + "元", 513), [
              [_vShow, _ctx.judgeAmount(_ctx.collectInfos.totalOrderAmount)]
            ]),
            _withDirectives(_createElementVNode("div", { class: "collect-item" }, "企业实付总额: " + _toDisplayString(_ctx.collectInfos.enterprisePayRealAmount) + "元", 513), [
              [_vShow, _ctx.judgeAmount(_ctx.collectInfos.enterprisePayRealAmount)]
            ]),
            _withDirectives(_createElementVNode("div", { class: "collect-item" }, "员工三方支付总额: " + _toDisplayString(_ctx.collectInfos.individualPayRealAmount) + "元", 513), [
              [_vShow, _ctx.judgeAmount(_ctx.collectInfos.individualPayRealAmount)]
            ]),
            _withDirectives(_createElementVNode("div", { class: "collect-item" }, "加油升数L: " + _toDisplayString(_ctx.collectInfos.totalLitre), 513), [
              [_vShow, _ctx.judgeAmount(_ctx.collectInfos.totalLitre)]
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_app_table, {
        ref: "tableRef",
        size: "default",
        "row-key": "orderNo",
        "show-pagination": true,
        "init-load": false,
        columns: _ctx.tableColumns,
        data: _ctx.loadTableData,
        "row-selection": _ctx.selectState,
        scroll: { x:'100%', y: 500 }
      }, {
        accountDeptNames: _withCtx(({ record }) => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(record.accountDeptNames), 1)
        ]),
        energyType: _withCtx(({ record }) => [
          _createElementVNode("span", _hoisted_5, _toDisplayString(record.energyType ||'充电'), 1)
        ]),
        payType: _withCtx(({ record }) => [
          (record.payType === 9)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "企业支付(员工)"))
            : _createCommentVNode("", true),
          (record.payType === 8)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, "企业支付(车辆)"))
            : _createCommentVNode("", true)
        ]),
        "order-status": _withCtx(({ record }) => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("i", {
              class: "status-dot",
              style: _normalizeStyle({ backgroundColor: _ctx.statusColorMap[record.orderStatus] })
            }, null, 4),
            _createElementVNode("span", null, _toDisplayString(_ctx.orderStatusMap[record.orderStatus] || '未知'), 1)
          ])
        ]),
        paymentRemark: _withCtx(({ record }) => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              class: "content",
              title: record.paymentRemark
            }, _toDisplayString(record.paymentRemark), 9, _hoisted_10),
            _createElementVNode("a", {
              href: "#",
              onClick: ($event: any) => (_ctx.onEditRemark(record, 1, 'oil'))
            }, "【查看】", 8, _hoisted_11),
            _createElementVNode("a", {
              href: "#",
              onClick: ($event: any) => (_ctx.onEditRemark(record, 2, 'oil'))
            }, "【编辑】", 8, _hoisted_12)
          ])
        ]),
        chargePaymentRemark: _withCtx(({ record }) => [
          (_ctx.showRemark(record))
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: ($event: any) => (_ctx.onEditRemark(record, 1, 'charge'))
                }, "【查看】", 8, _hoisted_14),
                _createElementVNode("a", {
                  href: "#",
                  onClick: ($event: any) => (_ctx.onEditRemark(record, 2, 'charge'))
                }, "【编辑】", 8, _hoisted_15)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, "---"))
        ]),
        action: _withCtx(({ record }) => [
          _createElementVNode("a", {
            href: "#",
            onClick: _withModifiers(($event: any) => (_ctx.onViewItemDetail(record)), ["prevent"])
          }, "详情", 8, _hoisted_17)
        ]),
        _: 1
      }, 8, ["columns", "data", "row-selection"])
    ]),
    _createVNode(_component_a_modal, {
      visible: _ctx.confirmVisible,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.confirmVisible) = $event)),
      title: "确认导出订单?",
      "confirm-loading": _ctx.exporting,
      "ok-text": "导出",
      onOk: _ctx.onConfirmExport
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.exportParams,
          "label-col": { span: 5 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "交易时间",
              help: "最长可导出90个自然日数据",
              class: "marginBottom"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_range_picker, {
                  value: _ctx.exportParams.timeRange,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.exportParams.timeRange) = $event)),
                  format: "YYYY-MM-DD",
                  placeholder: ['起始时间', '结束时间'],
                  style: {"width":"250px"},
                  "disabled-date": _ctx.isDateDisable,
                  onCalendarChange: _ctx.onCalendarChange,
                  onOpenChange: _ctx.onOpenChange
                }, null, 8, ["value", "disabled-date", "onCalendarChange", "onOpenChange"])
              ]),
              _: 1
            }),
            (_ctx.type !== 3)
              ? (_openBlock(), _createBlock(_component_a_form_item, {
                  key: 0,
                  label: "车牌号码",
                  class: "marginBottom"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.exportParams.licensePlateNo,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.exportParams.licensePlateNo) = $event)),
                      valueModifiers: { trim: true },
                      "allow-clear": "",
                      placeholder: "车牌号码"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.type === 1)
              ? (_openBlock(), _createBlock(_component_a_form_item, {
                  key: 1,
                  label: "车牌识别代号",
                  class: "marginBottom"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.exportParams.vin,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.exportParams.vin) = $event)),
                      valueModifiers: { trim: true },
                      class: "filterInput",
                      "allow-clear": "",
                      placeholder: "车牌识别代号"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_a_form_item, { label: "公司" }, {
              default: _withCtx(() => [
                (_ctx.authority === 0)
                  ? (_openBlock(), _createBlock(_component_app_enterprise_input, {
                      key: 0,
                      value: _ctx.currentEnterpriseId,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentEnterpriseId) = $event)),
                      style: {"width":"200px"},
                      "with-default-all": ""
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true),
                (_ctx.authority === 1)
                  ? (_openBlock(), _createBlock(_component_app_enterprise_input, {
                      key: 1,
                      value: _ctx.currentEnterpriseId,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentEnterpriseId) = $event)),
                      style: {"width":"200px"}
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "部门" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tree_select, {
                  value: _ctx.exportParams.departmentId,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.exportParams.departmentId) = $event)),
                  style: {"width":"180px"},
                  "allow-clear": "",
                  "show-search": "",
                  placeholder: "部门名称",
                  "search-placeholder": "请选择",
                  "show-line": true,
                  "tree-node-filter-prop": "title",
                  "tree-data": _ctx.departmentList
                }, null, 8, ["value", "tree-data"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "订单状态",
              class: "marginBottom"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.exportParams.orderStatus,
                  "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.exportParams.orderStatus) = $event)),
                  class: "placeholder-highlight",
                  placeholder: "全部",
                  style: {"width":"250px"},
                  mode: "multiple"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStatusList, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.value,
                        value: item.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "confirm-loading", "onOk"]),
    _createVNode(_component_detail_drawer, {
      ref: "detailRef",
      label: _ctx.label,
      "order-mark-open": _ctx.orderMarkOpen,
      type: _ctx.type,
      "is-show-pay-discount-amount": _ctx.isShowPayDiscountAmount,
      "is-show-discount-card-reduce-amount": _ctx.isShowDiscountCardReduceAmount,
      "is-show-third-party-coupon-discount-amount": _ctx.isShowThirdPartyCouponDiscountAmount,
      "is-show-discount-card-id": _ctx.isShowDiscountCardId,
      "is-show-prepaid-card-card-nos": _ctx.isShowPrepaidCardCardNos,
      onComplete: _ctx.onRefreshTable
    }, null, 8, ["label", "order-mark-open", "type", "is-show-pay-discount-amount", "is-show-discount-card-reduce-amount", "is-show-third-party-coupon-discount-amount", "is-show-discount-card-id", "is-show-prepaid-card-card-nos", "onComplete"]),
    _createVNode(_component_EditRemark, {
      ref: "editRemarkRef",
      onComplete: _ctx.updateRemark
    }, null, 8, ["onComplete"]),
    _createVNode(_component_ImageViewer, {
      visible: _ctx.remarkPicVisible,
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.remarkPicVisible) = $event)),
      "preview-src-list": _ctx.payRemarkImageList
    }, null, 8, ["visible", "preview-src-list"]),
    _createVNode(_component_BatchSign, {
      "batch-visible": _ctx.batchVisible,
      "selected-rows": _ctx.selectState.selectedRowKeys,
      "order-type": _ctx.type,
      onCloseModal: _ctx.closeModal,
      onOnRefreshTable: _ctx.onRefreshTable
    }, null, 8, ["batch-visible", "selected-rows", "order-type", "onCloseModal", "onOnRefreshTable"])
  ]))
}